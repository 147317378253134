export const white = "#FFFFFF";
export const black = "#000000";
export const black1c = "#1C1B1F";
export const black1f = "#1F1F1F";
export const gray49 = "#49454F";
export const grayf9 = "#F9F6F2";
export const gray45 = "#454545";
export const grayee = "#EEEEEE";
export const graydd = "#DDDDDD";
export const graye8 = "#e8e8e8";
export const gray62 = "#625B71";
export const grayf5 = "#F5F5F5";
export const graye5 = "#E5E5E5";
export const purple86 = "#8652FF";
export const purpleef = "#EFE8F5";
export const redb3 = "#B3261E";
export const success = "#23D068";
export const warning = "#FFCC41";
export const error = "#D8232A";
export const footer_gray = "#D8D8D8";
export const dark_gray = "#1C1B1FA3";

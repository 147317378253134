const SidebarIcon1 = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill={color ? color : "none"}
    >
      <path
        d="M11.4858 10.3706C11.6506 9.87646 12.3495 9.87647 12.5142 10.3706L13.4824 13.2752C13.914 14.57 14.93 15.586 16.2247 16.0176L19.1294 16.9858C19.6235 17.1505 19.6235 17.8494 19.1294 18.0142L16.2247 18.9824C14.93 19.414 13.914 20.43 13.4824 21.7247L12.5142 24.6294C12.3495 25.1235 11.6506 25.1235 11.4858 24.6294L10.5176 21.7247C10.086 20.4299 9.07003 19.414 7.77529 18.9824L4.8706 18.0142C4.37647 17.8494 4.37647 17.1505 4.8706 16.9858L7.7753 16.0176C9.07003 15.586 10.086 14.57 10.5176 13.2753L11.4858 10.3706Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.69151 2.72236C5.79034 2.42588 6.20971 2.42588 6.30853 2.72236L6.88946 4.46515C7.14841 5.242 7.758 5.8516 8.53485 6.11055L10.2777 6.69148C10.5741 6.79031 10.5741 7.20968 10.2777 7.3085L8.53485 7.88944C7.758 8.14839 7.14841 8.75799 6.88946 9.53484L6.30853 11.2776C6.20971 11.5741 5.79034 11.5741 5.69151 11.2776L5.11056 9.53482C4.85161 8.75798 4.24202 8.14839 3.46518 7.88944L1.72236 7.3085C1.42588 7.20968 1.42588 6.79031 1.72236 6.69148L3.46518 6.11054C4.24202 5.8516 4.85161 5.24201 5.11057 4.46517L5.69151 2.72236Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2943 1.14824C16.3602 0.950586 16.6398 0.950587 16.7057 1.14824L17.093 2.3101C17.2656 2.828 17.672 3.2344 18.1899 3.40703L19.3518 3.79432C19.5494 3.86021 19.5494 4.13978 19.3518 4.20567L18.1899 4.59296C17.672 4.76559 17.2656 5.17199 17.093 5.68989L16.7057 6.85176C16.6398 7.04941 16.3602 7.04941 16.2943 6.85176L15.907 5.68988C15.7344 5.17198 15.328 4.76559 14.8101 4.59296L13.6483 4.20567C13.4506 4.13978 13.4506 3.86021 13.6483 3.79432L14.8101 3.40703C15.328 3.2344 15.7344 2.82801 15.9071 2.31011L16.2943 1.14824Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default SidebarIcon1;

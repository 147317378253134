const SidebarIcon2 = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color ? color : "none"}
    >
      <path
        d="M4.45607 2.02381C4.74104 1.69134 5.15706 1.5 5.59495 1.5H18.405C18.8429 1.5 19.259 1.69134 19.5439 2.02381L23.4583 6.59061C23.8079 6.9984 24 7.51779 24 8.05489V8.4375C24 10.405 22.405 12 20.4375 12C19.2943 12 18.2769 11.4615 17.625 10.6244C16.9731 11.4615 15.9557 12 14.8125 12C13.6693 12 12.6519 11.4615 12 10.6244C11.3481 11.4615 10.3307 12 9.1875 12C8.0443 12 7.02687 11.4615 6.375 10.6244C5.72314 11.4615 4.7057 12 3.5625 12C1.59499 12 0 10.405 0 8.4375V8.05489C0 7.51779 0.192132 6.9984 0.541673 6.59061L4.45607 2.02381ZM7.125 8.4375C7.125 9.57659 8.04841 10.5 9.1875 10.5C10.3266 10.5 11.25 9.57659 11.25 8.4375C11.25 8.02329 11.5858 7.6875 12 7.6875C12.4142 7.6875 12.75 8.02329 12.75 8.4375C12.75 9.57659 13.6734 10.5 14.8125 10.5C15.9516 10.5 16.875 9.57659 16.875 8.4375C16.875 8.02329 17.2108 7.6875 17.625 7.6875C18.0392 7.6875 18.375 8.02329 18.375 8.4375C18.375 9.57659 19.2984 10.5 20.4375 10.5C21.5766 10.5 22.5 9.57659 22.5 8.4375V8.05489C22.5 7.87585 22.436 7.70272 22.3194 7.56679L18.405 3H5.59495L1.68056 7.56679C1.56404 7.70272 1.5 7.87585 1.5 8.05489V8.4375C1.5 9.57659 2.42341 10.5 3.5625 10.5C4.70159 10.5 5.625 9.57659 5.625 8.4375C5.625 8.02329 5.96079 7.6875 6.375 7.6875C6.78921 7.6875 7.125 8.02329 7.125 8.4375ZM2.25 12.75C2.66421 12.75 3 13.0858 3 13.5V22.5H21V13.5C21 13.0858 21.3358 12.75 21.75 12.75C22.1642 12.75 22.5 13.0858 22.5 13.5V22.5H23.25C23.6642 22.5 24 22.8358 24 23.25C24 23.6642 23.6642 24 23.25 24H0.75C0.335786 24 0 23.6642 0 23.25C0 22.8358 0.335786 22.5 0.75 22.5H1.5V13.5C1.5 13.0858 1.83579 12.75 2.25 12.75ZM5.25 13.5C5.66421 13.5 6 13.8358 6 14.25V19.5H18V14.25C18 13.8358 18.3358 13.5 18.75 13.5C19.1642 13.5 19.5 13.8358 19.5 14.25V19.5C19.5 20.3284 18.8284 21 18 21H6C5.17157 21 4.5 20.3284 4.5 19.5V14.25C4.5 13.8358 4.83579 13.5 5.25 13.5Z"
        fill="black"
      />
      <path
        d="M3 11.5L0.5 8.5L5.08333 3L5.5 2.5V3L18 2L21.5 4.5L24 9L21.5 11.5L18 9.5L14.5 11L12 9.5L9.5 11L6.5 9.5L3 11.5Z"
        fill={color ? color : "none"}
      />
    </svg>
  );
};
export default SidebarIcon2;
